<template>
    <div class="flex flex-auto flex-column" >
        <div class="flex flex-auto" >
            <div class="w-9 mr-4 flex flex-auto flex-column content-area">
                <h2>Häufig gestellte Fragen</h2>
                <div class="scrollable-flex pt-2">
                <Accordion :activeIndex="0">
                    <AccordionTab header="Erste wichtige Frage">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </AccordionTab>
                    <AccordionTab header="Zweite wichtige Frage">
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                    </AccordionTab>
                    <AccordionTab header="Dritte und letze Frage">
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                    </AccordionTab>
                </Accordion>
                </div>
            </div>
            
            <div class="w-3 mr-4 flex flex-auto flex-column content-area">
                <div class="mb-2 text-xl mb-4 font-bold">Kontakt</div>
                <div class="scrollable-flex pt-2">
                    <p class="font-bold">LEHNER GmbH SENSOR-SYSTEME</p>
                    <p><span class="font-bold">E-Mail</span><br />info@lehner-gmbh.com</p>
                    <p><span class="font-bold">Telefon</span><br />+49 7021 / 97013-0</p>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
//import { /*ref, */ computed, onMounted, watch } from  "vue";
//import { useStore } from "vuex";
//import { useRouter } from "vue-router";

export default ({
    name: "HelpPage",
    components: {  },
    
    setup() {

        return { };
    },
})
</script>

<style scoped>

.search-result-item {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03), 
                0px 0px 2px rgba(0, 0, 0, 0.06), 
                1px -1px 4px rgba(0, 0, 0, 0.10), 
                0px 2px 6px rgba(0, 0, 0, 0.12); 
}

.search-result-item:hover {
    box-shadow: 0px 4px 6px rgba(121, 110, 216, 0.2), 
                0px 0px 2px rgba(121, 110, 216, 0.60),
                2px 1px 4px rgba(121, 110, 216, 0.60), 
                0px 2px 6px rgba(121, 110, 216, 0.6); 
}

</style>

